import * as React from "react"
import Layout from "../layout"

const Blog = () => (
  <>
    <Layout>
      <h1>Coming soon</h1>
    </Layout>
  </>
)

export default Blog
